@font-face {
  font-family: "apercuregular";
  src: url("./fonts/apercu_regular_pro.woff2") format("woff2"),
    url("./fonts/apercu_regular_pro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "apercumedium";
  src: url("./fonts/apercu_medium_pro.woff2") format("woff2"),
    url("./fonts/apercu_medium_pro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "apercubold";
  src: url("./fonts/apercu_bold_pro.woff2") format("woff2"),
    url("./fonts/apercu_bold_pro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "aileronregular";
  src: url("./fonts/aileron_thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Noves fonts definides */
/* Titles */
@font-face {
  font-family: "cooperhewitt";
  src: url("./fonts/cooper-hewitt.thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cooperhewittmedium";
  src: url("./fonts/cooper-hewitt.medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "cooperhewittbold";
  src: url("./fonts/CooperHewitt-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sangbleu";
  src: url("./fonts/sangbleu-kingdom-light.ttf") format("truetype"),
    url("./fonts/SangBleuKingdom-Light-WebXL.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sangbleubold";
  src: url("./fonts/SangBleuKingdom-Bold-WebXL.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
h1 {
  font-family: cooperhewittmedium;
}
h2 {
  font-family: cooperhewitt;
}
h5 {
  font-family: cooperhewittmedium;
}
hr.solid {
  border-top: 3px solid #bbb;
}
.title {
  font-family: "cooperhewitt" !important;
  font-weight: lighter;
  font-size: 55px;
  line-height: 60px;
  color: #6a5045;
}

.subtitle {
  font-family: "cooperhewitt" !important;
  font-weight: lighter;
  font-size: 20px;
  line-height: 30px;
  color: #6a5045;
}

.bodycontent {
  font-family: "sangbleu" !important;
  font-size: 16px;
  line-height: 20px;
  color: black;
}

.cooperhewitt {
  font-family: "cooperhewitt" !important;
  appearance: none;
}

.cooperhewittbold {
  font-family: "cooperhewittmedium" !important;
}

.sangbleu {
  font-family: "sangbleu" !important;
}

.sangbleubold {
  font-family: "sangbleubold" !important;
}

.apercuregular {
  font-family: "apercuregular" !important;
}
.apercubold {
  font-family: "apercubold" !important;
}
.apercumedium {
  font-family: "apercumedium" !important;
}

.aileronregular {
  font-family: "aileronregular" !important;
}
.cursorpointer {
  cursor: pointer;
}
body {
  font-family: "sangbleu" !important;
  font-size: 14px;
  background-color: white !important;
  color: black !important;
}
p {
  font-family: "aileronregular" !important;
}
a {
  font-family: "cooperhewitt" !important;
  text-decoration: none !important;
  color: inherit !important;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track-piece {
  background-color: #262626;
}
::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: black;
}
.logo {
  width: 240px;
}
.footer {
  background-color: #6a5145;
  color: white;
}
button {
  background-color: #535353;
  color: white;
  border-radius: 20px !important;
  border: 0;
  padding: 4px 20px 2px 20px;
}
.buttonsubmit {
  background-color: #535353 !important;
  color: white !important;
  border-radius: 20px !important;
  border: 0 !important;
  padding: 4px 20px 2px 20px !important;
}
button:hover {
  background-color: white;
  color: #505050;
  border-radius: 20px !important;
  border: 0;
  padding: 4px 20px 2px 20px;
}
.btngrey {
  background-color: #535353;
  color: white;
  text-align: center;
  padding: 4px 20px 2px 20px;
}
.btnbrown {
  background-color: #6a5045;
  color: white;
}
.bgwhite {
  background-color: white;
}
.bglightgrey {
  background-color: #cfc4bf;
}
.bglightgrey2 {
  background-color: #e5e1df9c;
}
.bglightgrey3 {
  background-color: #d9d9d9;
}
.bglightbrown {
  background-color: #efebe3;
}
.bglightbrown2 {
  background-color: #cfc5bf;
}
.bgbrown {
  color: black;
}
.colorbrown {
  color: #6a5045;
}
.colorgrey {
  color: #535353;
}
.submenu {
  height: 24px;
}
.nav-item {
  padding: 4px 10px 2px 10px;
}
.nav-item:hover {
  background-color: #6a5145;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  width: fit-content;
}
.nav-item:hover a {
  color: white !important;
}
.nav-link {
  color: #505050 !important;
  padding: 2px 5px 2px 5px !important;
  text-align: center !important;
}
.nav-link:hover {
  background-color: #6a5145;
  color: white !important;
}
.link-style {
  background-color: #535353;
  color: white;
  border-radius: 20px !important;
  border: 0;
  padding: 4px 20px 2px 20px;
  text-align: center;
}
.linkspan {
  color: white;
  /* padding: 4px 20px 2px 20px; */
}
.link-style:hover {
  background-color: white;
  color: #505050;
}
.link-style:hover span {
  color: #505050 !important;
}
.submenu .nav-link:hover {
  background-color: inherit;
  color: black !important;
}
hr {
  margin-bottom: 3px !important;
}
.icoarrow {
  width: 13px;
}
.fs26 {
  font-size: 26px;
}

/*scroll button*/
.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
  padding-left: 40px !important;
  background-color: black;
  text-align: center;
}
.icon-position img {
  margin-left: 20px;
}
.icon-position:hover {
  background-color: #535353;
  color: white;
}
.f20 {
  font-size: 20px;
}
.ico-footer {
  margin-top: 20px;
  border-radius: 20px;
  background-color: #b09e96;
  padding-left: 4px;
  width: fit-content;
  padding-right: 6px;
  padding-bottom: 2px;
}
.ico-rrss-top {
  border-radius: 20px;
  background-color: #b09e96;
  padding-left: 4px;
  width: fit-content;
  padding-right: 6px;
  padding-bottom: 2px;
  padding-top: 4px;
  height: 33px;
  margin-top: 0px;
  margin-left: 6px;
}
.icoseal {
  margin-top: 30px;
  max-height: 80px;
}
.languages {
  width: 100%;
  background-color: #b09e96;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  text-align: center;
}
.languages.active {
  display: block !important;
}
.languages div {
  border-bottom: 1px solid white;
  padding-bottom: 4px;
  padding-top: 4px;
  cursor: pointer;
}
.languages div:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.languages div:last-child {
  border-bottom: 0;
  padding-bottom: 4px;
  padding-top: 4px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.languages div:hover {
  background-color: white;
  color: #6a5145;
}
.icomatsos {
  width: 100px !important;
  transform: rotate(-10deg);
  z-index: 10000;
  margin-bottom: -20px;
}

/** HOME **/
.pr0 {
  padding-right: 0px !important;
}
.pl0 {
  padding-left: 0px !important;
}
.bg-b1 {
  background-image: url(./assets/bg_bloque1_home.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 650px;
  background-color: #e1dee1;
  animation-name: transitionb1;
  animation-duration: 10s;
}
@keyframes transitionb1 {
  0% {
    background-position: 180px;
  }
  100% {
    background-position: 0px;
  }
}
.bg_soluciones {
  background-image: url(./assets/bg_soluciones_home.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.bg_3_home {
  background-image: url(./assets/bg_3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.bg_4_home {
  background-image: url(./assets/bg_4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.bg_5_home {
  background-image: url(./assets/bg_5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.bg_6_home {
  background-image: url(./assets/bg_6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.bg_7_home {
  background-image: url(./assets/bg_7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.bg_8_home {
  background-image: url(./assets/bg_8.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.bg_9_home {
  background-image: url(./assets/bg_9.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg_10_home {
  background-image: url(./assets/bg_10.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg_11_home {
  background-image: url(./assets/bg_11.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.carousel-inner {
  -webkit-box-shadow: 0px 0px 10px 2px rgba(100, 100, 100, 0.4);
  box-shadow: 0px 0px 10px 2px rgba(100, 100, 100, 0.4);
}
.carousel-control-prev {
  display: none !important;
}
.carousel-control-next-icon {
  background-color: #6a5045;
  border-radius: 20px;
  background-size: 25px !important;
}

/** ABOUT PUJOLASOS **/
.bg-abouthistoria {
  background-image: url(./assets/about-historia.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-abouthistoria2 {
  background-image: url(./assets/about-historia2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  background-attachment: fixed;
}
.bg-abouthistoria3 {
  background-image: url(./assets/about-historia3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-mision {
  background-image: url(./assets/about-mision.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-vision {
  background-image: url(./assets/about-vision.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-aboutlast {
  background-image: url(./assets/about-last.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  background-attachment: fixed;
}

/** CONTACT **/
.bg-headercontacto {
  background-image: url(./assets/header-contacto.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  background-attachment: fixed;
}
input,
textarea {
  border: 0;
  border-bottom: 1px solid black;
  background-color: inherit;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}
.map-container {
  height: 350px;
  width: 100%;
}

/** SUSTAINABILITY **/
.bg-sustainability {
  background-image: url(./assets/header-sustainability.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-sustainability2 {
  background-image: url(./assets/header2-sustainability.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  background-attachment: fixed;
}

/** PLANT FOR THE PLANET **/
.bg-plantfortheplanet {
  background-image: url(./assets/header-sustainability.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-sostenibilidadpartner {
  background-image: url(./assets/sostenibilidad-partner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-sostenibilidadpartner2 {
  background-image: url(./assets/sostenibilidad-partner2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-sostenibilidadpartner3 {
  background-image: url(./assets/sostenibilidad-partner3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.icosostconainer {
  min-height: 150px;
}
.bg-sostenibilidadfooter1 {
  background-image: url(./assets/sostenibilidad-footer1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-sostenibilidadfooter2 {
  background-image: url(./assets/sostenibilidad-footer2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-billonarboles {
  background-image: url(./assets/billon_arboles.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}

/** PERFUME **/
.sup-bg-perfume3:hover .bg-perfume3,
.sup-bg-perfume3:focus .bg-perfume3 {
  transform: scale(1.2);
}
.sup-bg-perfume3 {
  overflow: hidden;
}
.bg-perfume {
  background-image: url(./assets/perfume-tranformando.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-perfume2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  object-fit: cover;
  margin-bottom: -7px;
}
.bg-perfume3 {
  background-image: url(./assets/perfume-tranformando3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.bg-perfumecomprometidos {
  background-image: url(./assets/perfume-comprometidos.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  background-attachment: fixed;
}
.bg-perfumecomprometidos2 {
  background-image: url(./assets/perfume-comprometidos2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-perfumeconstanteinvestigacion {
  background-image: url(./assets/perfume-constanteinvestigacion.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  min-height: 450px;
}
.bg-perfumenuestrosdesarrollos {
  background-image: url(./assets/perfume-nuestrosdesarrollos.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-perfumebodegon {
  background-image: url(./assets/perfume-bodegon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  background-attachment: fixed;
}
.bg-perfumecreamospiezas {
  background-image: url(./assets/perfume-creamospiezas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-perfumeentendernecesidades {
  background-image: url(./assets/perfume-entendernecesidades.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.btnoutline {
  border: 1px solid #535353;
  background-color: inherit;
  color: #535353;
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.btnoutline:hover {
  border: 1px solid #535353;
  background-color: #535353;
  color: white;
}

/** COSMETICS **/
.sup-bg-cosmeticsskincare:hover .bg-cosmeticsskincare,
.sup-bg-cosmeticsskincare:focus .bg-cosmeticsskincare,
.sup-bg-cosmeticsmakeup:hover .bg-cosmeticsmakeup,
.sup-bg-cosmeticsmakeup:focus .bg-cosmeticsmakeup {
  transform: scale(1.1);
}
.sup-bg-cosmeticsskincare,
.sup-bg-cosmeticsmakeup {
  overflow: hidden;
  cursor: pointer;
}
.bg-cosmeticsheader {
  background-image: url(./assets/cosmetics-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.bg-cosmeticsskincare {
  background-image: url(./assets/cosmetics-skincare.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.bg-cosmeticsmakeup {
  background-image: url(./assets/cosmetics-makeup.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

/** MAKEUP **/
.bg-makeupheader {
  background-image: url(./assets/makeup-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}

/** HOME **/
.bg-homeback1 {
  background-image: url(./assets/home-back1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-homeback2 {
  background-image: url(./assets/home-back2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.bg-homeback3 {
  background-image: url(./assets/home-back3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-homeback4 {
  background-image: url(./assets/home-back4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}

/** I+D **/
.bg-imasdback1 {
  background-image: url(./assets/imasd-back1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-imasdback2 {
  background-image: url(./assets/imasd-back2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-imasdback3 {
  background-image: url(./assets/imasd-back3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}

/** OUR INNOVATIONS **/
.bg-innovationswoorigin {
  background-image: url(./assets/innovations-woorigin.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-innovationswoodle {
  background-image: url(./assets/innovations-woodle.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-innovationsback1 {
  background-image: url(./assets/innovations-back1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  background-attachment: fixed;
}
.bg-innovationswoon {
  background-image: url(./assets/innovations-woon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-innovationswoork {
  background-image: url(./assets/innovations-woork.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-innovationsprefill {
  background-image: url(./assets/innovations-prefill.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.videoourinnovations {
  object-fit: cover;
  height: 50vh !important;
  width: 100%;
}

/** WOODLE **/
.header-woodle {
  background-image: url(./assets/woodle-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.main-woodle {
  background-image: url(./assets/woodle-main.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}

/** WOON **/
.header-woon {
  background-image: url(./assets/woon-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.main-woon {
  background-image: url(./assets/woon-main.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}

/** WOORIGIN **/
.header-woorigin {
  background-image: url(./assets/woorigin-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.main-woorigin {
  background-image: url(./assets/woorigin-main.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}

/** WOORK **/
.header-woork {
  background-image: url(./assets/woork-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
}
.main-woork {
  background-image: url(./assets/woork-main.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}

/** PREFILL **/
.header-prefill {
  background-image: url(./assets/prefill-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  min-height: 650px;
}
.main-prefill {
  background-image: url(./assets/prefill-main.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}

/** SKIN CARE **/
.bg-skincare1 {
  background-image: url(./assets/bg-skincare1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 950px;
}
.bg-skincare2 {
  background-image: url(./assets/bg-skincare2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 950px;
}

/** MAKE UP **/
.bg-makeup1 {
  background-image: url(./assets/makeup1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 950px;
}
.makeupheader {
  margin-bottom: -50px;
}
.bg-makeup4 {
  background-image: url(./assets/makeup4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 950px;
}

/** RESOURCE GALLERY **/
.containerimggallery {
  overflow: hidden;
}
.imggallery {
  cursor: pointer;
  max-height: 250px;
  width: 100%;
  object-fit: cover;
}
.br20 {
  border-radius: 10px;
}
.br15 {
  border-radius: 5px;
}
.imgecovadis {
  max-width: 165px;
}
.datefont {
  font-size: 14px;
}

/** DETAIL NEWS **/
.img-detailnews {
  object-fit: cover;
  max-height: 450px;
  object-position: center;
  width: 100%;
}

@media (max-width: 992px) {
  .icosostconainer {
    min-height: inherit;
  }
  .submenu {
    height: 48px;
  }
  .submenu ul {
    left: 44px;
  }
  .pr0 {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .pl0 {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .img-detailnews {
    object-fit: cover;
    max-height: 250px;
    object-position: center;
    width: 100%;
  }
  .icomatsos {
    margin-bottom: 10px;
    margin-left: 20px;
  }
}

.ourinn1 {
  background-image: url(./assets/ourinn/ourinn1.png);
}
.ourinn2 {
  background-image: url(./assets/ourinn/ourinn2.png);
}
.ourinn3 {
  background-image: url(./assets/ourinn/ourinn3.png);
}
.ourinn4 {
  background-image: url(./assets/ourinn/ourinn4.png);
}
.ourinn5 {
  background-image: url(./assets/ourinn/ourinn5.png);
}

:root {
  --duration: 15s;
}
.wrapper-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  opacity: 1;
  transform: translate3d(-50%, -50%, 0);
}
.images-line {
  animation: runner var(--duration) linear infinite;
  display: flex;
  transform: translateX(23%);
  &:nth-child(even) {
    animation-duration: calc(var(--duration) * 1.5);
  }
  .line {
    background-position: 50% 50%;
    background-size: cover;
    flex: none;
    height: 30vh;
    margin: clamp(10px, 2vw, 50px);
    width: 30vh;
    position: relative;
    transition: ease-in-out all 0.3s;
    border-radius: 1rem;
    .img {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-position: 50% 50%;
      background-size: cover;
      transition: ease-in-out all 0.3s;
      overflow: hidden;
      border-radius: 1rem;
    }
    &:hover {
      .img {
        cursor: pointer;
        transform: scale(1.1) translatez(0px);
      }
      &:after {
        filter: blur(35px) opacity(1);
      }
    }
    &:after {
      content: "";
      background: inherit;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      top: 3vh;
      position: absolute;
      background-size: cover;
      z-index: -1;
      transition: ease-in-out all 0.3s;
      filter: blur(25px) opacity(1);
    }
  }
}

@keyframes runner {
  to {
    transform: translateX(-10.4%);
  }
}

.images-line .line .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 30%;
  left: 70%;
  margin-left: -60px;
  opacity: 0.8;
  transition: opacity 1s;
  z-index: 10000;
  border-radius: 20px;
}

.images-line .line:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.oxh {
  overflow-x: hidden;
}

.icofooter {
  position: absolute;
  width: 45px;
  top: 0px;
  left: 0px;
  margin-top: -17px;
}
.textfooter {
  margin-left: 50px;
  font-family: "cooperhewitt" !important;
}
.ico-valores {
  max-width: 120px;
}

.navigation {
  width: 100%;
  top: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  padding-right: 1%;
  background-color: white;
}

.navbar-hidden {
  transform: translateY(-100%);
}

.navbar-visible {
  transform: translateY(0);
}

.navbar-fixed {
  position: fixed;
}

.navbar-relative {
  position: relative;
}

.list ul {
  position: relative;
}
.list ul li {
  position: relative;
  left: -10px;
  list-style: none;
  margin: 1px 0px 10px 0px;
  border-left: 1px solid black;
  padding-left: 10px;
}

.p1 {
  padding: 5px;
  text-align: justify;
  align-items: center;
}
